.kumet{
    height: 70vh;
}
 img{
    object-fit: cover ;
    object-position:center;
}

.room-container {
    display: block;
    margin-top: 4rem;
}
.successinfo{
    background-color: aquamarine;
    height: 80px;
    width: 100%;
    border-radius: 10px;
}