// .service-grid{
//     color : pink;

//     width: 100%;
//     height: 18rem;
//     background: url(../assets/bar.JPG) no-repeat 50% 10%;
//     background-size: cover;
//     font-family: 'Poppins', sans-serif;
//     grid-gap: 30px;
//     display: flex;
// }
// h2 {
//     color: sandybrown;
//     text-align: center;
//     margin: 2rem auto;
//     font-family: 'Poppins', sans-serif;
// }
// .top{
//     align-items: flex-end ;
// }
// .bottom-thing{
//     bottom: 0;
//     right: 0;
// }
#services {
    padding: 5rem 0 5rem 0;
    h2{
        margin: 0rem 0 3rem 0;
    }
}
.must_visit{
    h2{
        margin: 0rem 0 3rem 0;
    }
    
    background-color: white;
  
    // display: block;
}