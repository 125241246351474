.cartcard{
     display: block;
        background-color: rgb(255, 255, 255);
        padding: 1rem;
        border-radius: 0.4rem;
       height: 100%;
        box-shadow: 2px 0px 8px rgba(0, 0, 0, 0.082);
        font-family: 'Poppins', sans-serif;
     

}
.cartcart-bigger{
    height: 100%;
    padding: 1rem 0;
}
.your-stay{
    font-size: 1.2rem;
    margin: 10px;
    display: block;
}
.hotel-cart-container{
    margin: 10px;
    
    /* flex-wrap: wrap; */
}
.checkin{
    padding: 10px 0;
   span{
       display: block;
   }
  
}
 .time-and-date{
       font-size: 0.9rem;
       color: rgb(70, 70, 70);
   }
.checkout{
    padding: 0px 0 10px 0;
    span{
        display: block;
    }
}

.action-cartcard{
    span{
        display: block;
    }
    .totlacartcard{
        font-size: 1.1rem;
    }
}
@media screen and (max-width : 768px){
    .cartcard{
      
        display: none;
    
    }
}