
@import './sidedrawer.scss';
@import './backdrop.scss';
@import './home.scss';
@import './about.scss';
@import './services.scss';
@import './form.scss';
@import './rooms.scss';
@import './navigation';
@import './card-style.scss';
// @import './userinfo.scss';

body{
    background-color: rgb(251, 251, 251);
}
.parentdiv{
    background-color: white;
    padding: 1rem;
    margin-top: 4rem;
    min-height: 100vh;
    
    margin-bottom: 0;
}
label{
    margin-top: 0;
    margin-bottom: 0;
}
h2 {
    color: sandybrown;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    margin: 2rem auto;
}
.btnbtn{
    display: inline-block;
    padding: 0.7rem 1.5rem;
    letter-spacing: 1px;
    text-transform: uppercase;
    font-size: 0.9rem;
    color : white; 
    border : none;
    text-align: center;
    background-image: linear-gradient(to right , sandybrown 0% , #c2834d 100%);
    border-radius: 0rem;
    text-decoration: none;
}
.btnbtn:hover{
    background-image: linear-gradient(to right , sandybrown 0% ,sandybrown 100%);
    text-decoration: none;
    color :white;
}
*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    :focus{
        outline: 0 !important;
  box-shadow: none;
    }
    
}
