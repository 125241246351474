@import '../../scss/color.scss';
.carousel{
    z-index: 9;
    .MuiSvgIcon-root {
        fill: sandybrown;
    }
    font-family: $paragraph-font
}
.carousel-background{
    background-color: #ffffff;
    height: 30rem;
}
.carousel-caption{
    .vption{
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;}
    
    justify-content: center;
    bottom: 15%;
    top: 15%;
    margin-left: 0;
    padding: 1rem;
    z-index: 10;
  
   
    p{
        
        line-height: 1.8;
        text-align: start;
        color: rgba(0, 0, 0, 0.637);
      
     
    }
    h3{
        // position: absolute;
       
        color: black;
        font-size: 3rem;

    }
    h4{
        // position: absolute;
        
        color: rgba(0, 0, 0, 0.753);
       
    }
}
.carousel-indicators li{
    width: 10px ;
    height: 10px;
    border-radius: 100%;
    background-color: sandybrown;
}
@media(max-width : 900px){
    .carousel-background{
        height: 30rem;
    }
    .carousel-caption{
        top:10%;
        bottom:10%;
        z-index: 10;
        p{
            line-height: 1.5;
            text-align: justify;
           
        }
        h3{
          
         
            font-size: 2.7rem;
    
        }
        h4{
           
           font-size: 1.2rem;
        }
    }
}
@media(max-width : 500px){
    .carousel-background{
        height: 33rem;
    }
    .carousel-caption{
     
        top:5%;
        bottom:10%;
        z-index: 10;
        padding-left: 0;
        padding-right: 0;
        p{
            line-height: 1.2;
            text-align: justify;
       
            padding-left: 0;
            padding-right: 0;
            font-size: 16px;
        }
        h3{
          
            color: black;
            font-size: 1.7rem;
    
        }
        h4{

           font-size: 1rem;
        } }
    }
    @media screen and (min-width: 320px) {
        p {
          font-size: calc(16px + 6 * ((100vw - 320px) / 10000));
        }
      }