.side-drawer{
    position: fixed;
    right: 0%;
    top: 0%;
    height: 100vh;
    width: 65%;
    max-width: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: rgb(255, 255, 255);
    transform: translateX(100%);
    transition: transform 0.3s ease-out ;
    box-shadow: 3px 9px 10px rgba(0, 0, 0, 0.6);
    justify-content: space-around   ;
    list-style: none;
    font-family: 'Poppins', sans-serif;
    z-index: 200;
    // transition: z-index 3s ease-in;
    a{
        color: rgb(212, 206, 206);
        text-decoration: none;
        letter-spacing: 3px;
        font-size: 14px;
    }
    
    a:hover, 
    a:active {
        color: sandybrown;
    }

   
}

.side-drawer.open {
    
        transform: translateX(0%);
        
       
}
@keyframes navLinkFade {
    from{
        opacity: 0;
        transition: translateX(50px);
    }
    to {
        opacity: 1;
        transition: translateX(0px);
    }

}

@media screen and (min-width : 761px){
    .side-drawer{
        display: none;
    }
}