.back-drop{
    height: 100%;
    top: 0;
    left: 0;
    width: 100%;
    position: fixed;
    background: rgba($color: #000000, $alpha: 0.2 );
    z-index: 18;
   
}
