.about {
    font-family: 'Poppins', sans-serif;
    position: relative;
    width: 100%;
    background-color: white;
    padding: 5rem 0 5rem 0;
    
    
    // background-image: linear-gradient(180deg, #cab058 0%, sandybrown 100%);
    // background-color: white;

    h2 {
        margin: 0rem 0 3rem 0;
    }
    p{
        line-height: 2;
 
        
    }
}
@media screen and (max-width: 700px){
    .about{
        p{
            line-height : 1.8;
            text-align: justify;
        }
    }
}

