
.background {
   position: relative;
    width: 100%;
    height: 60vh;
    background: url(../assets/restorant_balcony.jpg) no-repeat 100% 10%;
    background-size: cover;
    padding: 0;
    margin-bottom: 0;
    align-items: center;

    
    
}
.intro {
    position: absolute;
    min-height: 50%;
    background-image: linear-gradient(120deg, rgb(0, 0, 0) 0%, rgb(99, 95, 91) 100%);
    opacity: 0.6;
    margin: 10% 20%;
    justify-content: center;
    P{
        color: white;
        font-family: 'Poppins', sans-serif;
    }
}
// .textFront{
//     width : 25rem ;
//     box-sizing: border-box;
//     margin-left: auto;
// }