@import "./color.scss";
*,
::before,
::after{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
.imageContainer{
    height: 100%;
}
.roomcards{
    align-items: center;
    display: block;
    font-family: $paragraph-font;
}
.roomcard {
  
    
    margin : 1rem;
    padding: 1rem;
    /* background-color: #aaaaaa; */
    box-shadow: 2px 0px 8px rgba(0, 0, 0, 0.082);
    
    border-radius: 0.4rem;
}
.room-info{
    background-color: seagreen;
    margin-left: 1rem;
    height: 100%;
}
.room-img img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 0.4rem;
    display: block;
}

.room-img {
    /* background-color: thistle; */
    /* position: relative; */
    /* justify-content: center; */
    /* align-items: center; */
    overflow: hidden;

    height : 18rem;
    /* transform: translateX(-2rem); */
    /* margin-right: 1rem;   */
}
.desc-top{
    position: absolute;
    display: block;
    left: 0;
    right: 0;
    top: 0;
}
.desc-top h3{
    color: rgb(244, 164, 96)

}
.desc-top p{
    
    font-size: 0.9rem;
   color: rgba(0, 0, 0, 0.801);
}
.action-bottom{
    display: block;
    position: absolute;
    left: 0;
    bottom: 1rem;
    right: 0;
    /* justify-content: space-around; */
}
.rightsideinfo{
    padding-top: 30px;
    
}
.smt{
    width: 100%;
}
.action-bottom .btnbtn{
    display: block;
    /* background-color: black; */
    background-image: none;
    color: black;
    border: 1px solid sandybrown;
    border-radius: 10px;
    width: 100%;
    margin: auto auto;
}


/* @media screen and (max-width : 868px){
    .roomcard{
        max-width: 35rem;
    }
} */

@media screen and (max-width : 992px){
    .roomcard{
        
        padding: 1rem;
        flex-direction: column;
        
        margin: 1rem 0;
        /* margin: 1rem 1rem 2rem 1rem; */
        /* background-color: blue; */
    }

    .room-img {
        min-height: 15rem;
        
        display: inline;
        transform: translate(0 , -0rem);
    }
    .rightsideinfo{
        display: inline;
        
        height: 15rem;
        /* background-color: seagreen; */
        margin-left: 0;
    }
    .desc-top{
        position: relative;
        margin-bottom: 0;
    }
    .action-bottom{
        position: relative;
        padding-top: 5px;
    }
}
@media screen and (max-width: 450px){
   .rightsideinfo{
       height: 20rem;padding-top: 15px;
   }
   .room-img{
       height: 12rem;
       
   }
}