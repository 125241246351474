.slanding {
  height: 100vh;
  background: linear-gradient(0deg, rgba(2, 2, 2, 0.345), rgba(4, 3, 3, 0.3)),
    url("../assets/topmetting.jpg");
  background-size: cover;
  // vertical-align:middle;
  background-position: 50% 50%;
  align-content: flex-end;
  background-repeat: no-repeat;
}
