.card {
    width: 100%;
    margin: 0.5rem 0;
}

.card:hover{
box-shadow: 5px 10px 20px 1px rgba(0 , 0 , 0 , 0.253 ) !important;

}
.card_container{
    padding-bottom: 2rem;
}
// box-shadow: 5px 0px 5px 1px sandybrown   !important;

// box-shadow: 5px 10px 20px 1px rgba(0 , 0 , 0 , 0.253 ) !important;

.card-body{
    padding: 3rem 0 !important;
}
.card-text{
    font-size: 0.9rem;
    padding: 0.4rem 1.9rem;
}

.container-fluid .row{
    padding-top: 5rem;
}

.overflow {
    overflow: hidden;
}
.card-img-top{    
    // float: left;
    // width:  100%;
    // height: 30vh;
    // object-fit: cover;
    transform: scale(1); 
    transition: transform 0.5s ease;
}
.card-img-top:hover{
    transform: scale(1.2);
}